<template>
  <div class="container">
    <div class="photo-gallery">
      <div class="column" v-for="(image, index) in images" :key="index">
        <!-- Wrap the image in a div to make it clickable -->
        <div @click="openLightbox(index)">
          <img :src="require(`@/assets/images/${image}`)" alt="Image" />
        </div>
      </div>
    </div>
    <!-- Use vue-lightbox to display the lightbox -->
    <vue-lightbox :images="lightboxImages" :index="currentIndex" v-if="lightboxVisible"
      @close="lightboxVisible = false" />
  </div>
</template>
  
<script>

export default {
  components: {
  },
  // data() {
  // return {
  //   images: Array.from({ length: 144 }, (_, i) => `img - ${i + 1}.jpeg`),
  //   lightboxVisible: false,
  //   currentIndex: 0,
  // };
  // },

  data() {
    return {
      images: []
    };
  },

  created() {
    // Populate the images array with data before rendering
    this.images = Array.from({ length: 144 }, (_, i) => `img - ${i + 1}.jpeg`);
  },
};
</script>
  
<style>
/* Styles for the gallery component */
.photo-gallery {
  column-count: 3;
  /* Number of columns, adjust as needed */
  column-gap: 20px;
  /* Adjust the padding between images */
}

.column {
  display: inline-block;
  margin-bottom: 20px;
  /* Adjust the spacing at the bottom of the images */
  width: 100%;
}

img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
  /* Add equal spacing at the bottom of each image */
}

.container {
  margin-left: 5rem;
  margin-right: 5rem;
}

/* Media Query for screens below 900px */
@media (max-width: 900px) {
  .photo-gallery {
    column-count: 1
      /* Display images in one column on screens below 900px */
  }

  .container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
</style>
  