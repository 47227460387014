<template>
  <div class="photographer-section">
    <div class="photographer-info">
      <div class="photographer-image">
        <img :src="require('@/assets/images/portrait.jpg')" alt="Photographer" />
      </div>
      <div class="contact-info">
        <h1>Flavia Zaunseder</h1>
        <h2>Photographin</h2>
        <div class="contact-item">
          <span class="label">Telefon: </span>
          <span class="value">+49 160 9575 7803</span>
        </div>
        <div class="contact-item">
          <span class="label">Email: </span>
          <span class="value">flavia.zaunseder@t-online.de</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PhotographerPortrait',
  components: {
  },
};
</script>

<style>
/* Add styles to make the section appealing */
.photographer-section {
  padding: 40px 20px;
  margin-bottom: 2rem;
}

.photographer-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.photographer-image {
  margin-right: 40px; /* Increase the right margin to create padding */
}

.photographer-image img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.contact-info {
  flex: 1;
  text-align: left;
}

.contact-info h2 {
  font-size: 24px;
  margin-bottom: 1rem;
  margin-top: -1rem;
  font-weight: 150;
}

.contact-item {
  margin-bottom: 10px;
}

.contact-item .label {
  font-weight: bold;
}

.contact-info {
    text-align: center; /* Center the contact information */
  }

/* Media Query for screens below 900px */
@media (max-width: 900px) {
  .photographer-info {
    flex-direction: column; /* Display image above text on smaller screens */
    align-items: center; /* Center items horizontally */
  }

  .photographer-image {
    margin-right: 0; /* Remove right margin for mobile layout */
    margin-bottom: 20px; /* Add spacing between image and text */
  }


  
}

/* Additional style for screens above the breakpoint */
@media (min-width: 901px) {
  .photographer-info {
    flex-direction: column; /* Display image above text on larger screens */
    align-items: center; /* Center items horizontally */
  }

  .contact-info {
    text-align: center; /* Center the contact information */
  }

  .photographer-image {
    margin-bottom: 20px; /* Add spacing between image and text */
  }
}
</style>
