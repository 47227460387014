<template>
    <div class="impressum">
        <p>Angaben gemäß § 5 TMG</p>
        <p>Flavia Zaunseder <br>
            Kugelbühlstraße 36<br>
            91154 Roth <br>
        </p>
        <p> <strong>Vertreten durch: </strong><br>
            Flavia Zaunseder<br>
        </p>
        <p><strong>Kontakt:</strong> <br>
            Telefon: +49 160-9575 7803<br>
            E-Mail: <a href='mailto:flavia.zaunseder@t-online.de'>flavia.zaunseder@t-online.de</a>
        </p><br>
        <p><strong>Haftungsausschluss: </strong><br><br><strong>Haftung für Links</strong><br><br>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
            Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
            Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
            wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
            Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Links umgehend
            entfernen.<br><br><strong>Datenschutz</strong><br><br>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
            unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden,
            erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. <br>
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich. <br>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung
            von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich
            widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
            unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.<br>
        </p><br>
        Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a
            href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und
            Familienrecht</a>
    </div>
</template>


<script>
export default {
    name: 'ImpressumSeite',
};
</script>

<style>
/* Styles for the ImpressumSeite component */
.impressum {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.impressum h1 {
    text-align: center;
    margin-bottom: 20px;
}

/* Add padding for the Impressum section */
.impressum {
    padding-bottom: 5rem;
}
</style>
