<template>
  <div id="app" class="app-container">
    <!-- Always visible PhotographerPortrait component -->
    <div class="portrait-container">
      <PhotographerPortrait />
    </div>

    <div class="content-container">
      <!-- Dynamic content based on the selected section -->
      <div v-if="currentSection === 'gallery'">
        <!-- PhotoGallery component -->
        <PhotoGallery />
      </div>

      <div v-else>
        <!-- "Zurück zur Galerie" button -->
        <button @click="showSection('gallery')" class="back-button">Zurück zur Galerie</button>

        <!-- Impressum section content -->
        <div v-if="currentSection === 'impressum'" class="impressum">
          <h1>Impressum</h1>
          <ImpressumSeite />
        </div>

        <!-- Datenschutz section content -->
        <div v-else-if="currentSection === 'datenschutz'" class="datenschutz">
          <h1>Datenschutz</h1>
          <p>
            <DatenschutzErklärung />
          </p>
          <!-- ... other Datenschutz content ... -->
        </div>
      </div>
    </div>

    <nav>
      <!-- Navigationsmenü -->
      <a v-if="currentSection === 'gallery'" @click="showSection('impressum')">Impressum</a>
      <a v-if="currentSection === 'gallery'" @click="showSection('datenschutz')">Datenschutz</a>
      <a v-else @click="showSection('gallery')">Zurück zur Galerie</a>
    </nav>

    <!-- "Datenschutz" link at the bottom -->
    <div class="footer">&copy; 2023 Flavia Zaunseder</div>
  </div>
</template>

<script>
import PhotoGallery from './components/PhotoGallery.vue';
import PhotographerPortrait from './components/PhotographerPortrait.vue';
import DatenschutzErklärung from './components/DatenschutzErklärung.vue'
import ImpressumSeite from './components/ImpressumSeite.vue'

export default {
  name: 'App',
  components: {
    PhotoGallery,
    PhotographerPortrait,
    DatenschutzErklärung,
    ImpressumSeite,
  },
  data() {
    return {
      currentSection: 'gallery',
    };
  },
  methods: {
    showSection(section) {
      this.currentSection = section;
      // Scroll to the top of the page with smooth animation (1 second)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  }
}
</script>

<style>
html,
body {
  height: 100%;
  background-color: #5d5d5d;
  color: #ffffff;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 5rem;
  /* Add bottom margin of 5rem */
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.portrait-container {
  /* Add any styling for the portrait container */
  text-align: center;
  /* To center the portrait component */
  margin: 20px 0;
  flex: 1;
}

.content-container {
  /* Add any styling for the content container */
  padding: 20px;
  flex: 2;
}

footer {
  /* Add any styling for the footer container */
  padding: 20px;
  background-color: #808080;
  /* Gray background */
  flex: 0;
}

nav {
  /* Add any styling for the navigation links */
  flex: 0;
}

nav a {
  color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
  text-decoration: none;
}

nav a:hover {
  text-decoration: underline;
}

button {
  background-color: #ffffff;
  color: #808080;
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  cursor: pointer;
}

/* ... Your existing styles here ... */

/* Styles for the Datenschutz component */
.datenschutz {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.datenschutz h1 {
  text-align: center;
  margin-bottom: 20px;
}

/* Add padding for the Impressum and Datenschutz sections */
.impressum,
.datenschutz {
  padding-bottom: 5rem;
}

/* Styles for the Datenschutz link at the bottom */
.datenschutz-link {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #808080;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

.datenschutz-link:hover {
  background-color: #ffffff;
  color: #808080;
}

.footer {
  height: 5rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  text-align: center;
  margin-left: -1rem;
}
</style>
``
